<template>
  <div class="col-lg-8 padCustom">
    <!-- custom Html Start-->
    <div class="sectionTwoBoxWrap">
      <div class="insideItemBoxWrap">
        <div
          v-if="loading"
          class="d-flex flex-row sectionTwoSekelton"
          style="width: 100%; justify-content: space-between"
        >
          <v-skeleton-loader
            type="article"
            class="ma-2"
            width="47%"
            height="100%"
          />
          <v-skeleton-loader
            type="article"
            class="ma-2"
            width="47%"
            height="100%"
          />
        </div>
        <draggable
          v-else
          v-model="dashboardSort.section_2"
          class="section-two row"
          group="cards-tag"
          :class="isDisable ? 'section-two-in-active' : ''"
          :disabled="isDisable"
          @change="updateOrder"
        >
          <div
            v-for="(row, i) in dashboardSort?.section_2"
            :key="i"
            :loading="loading"
            class="col-lg-6"
          >
            <div class="largeItemBoxWrap">
              <div class="largeItemBoxHeading">
                <div class="dflexCustom">
                  {{ row.card_name }}
                  <span
                    v-if="row.card_name == 'Trips'"
                    class="open-link"
                    @click="editItem()"
                  >
                    <Icons name="external-link" />
                  </span>
                </div>
                <button
                  v-if="visibleButton && row.card_name === 'Trips'"
                  class="reset-button chartFilterButton"
                  @click="resetToInitialStage"
                >
                  <v-icon>mdi-reload</v-icon>
                </button>
              </div>
              <div
                v-if="row.card_name == 'Trips'"
                class="insideLargeItemBoxContentWrap"
              >
                <div class="apexBasicChart apexTripsChart">
                  <ApexBarChartWithPlotOptions
                    ref="tripChart"
                    :options="options"
                    :data="dashboadVehicleTrips.trips_chart_data"
                    :width="'100%'"
                    :height="'250px'"
                    :cursor-drill="cursorDrill"
                    @resetCursorValue="resetCursorValue"
                    @resetButton="resetButton"
                  />
                </div>
                <div
                  v-for="(val, st) in row.keys"
                  :key="st"
                  :class="val == 'active_trips' && 'active'"
                  class="largeItemContentBoxWrap tripsContentBoxes"
                  style="pointer-events: none"
                  @click="val == 'active_trips' && openActiveModal()"
                >
                  <div :class="['largeItemsContentBoxes', getIconColor(val)]">
                    <div class="iconBox">
                      <Icons :name="getIconName(val)" />
                    </div>
                    <div class="contentBox">
                      <p :class="val == 'active_trips' ? 'oopen-link' : ''">
                        <span>Total</span> {{ $t(`${val}`) }}
                      </p>
                    </div>
                    <div class="valueBox tripsValueBox">
                      {{ dashboadVehicleTrips[val] }}
                    </div>
                  </div>
                </div>
              </div>

              <div
                v-else-if="row.card_name === 'Vehicle Status'"
                :class="[
                  'insideLargeItemBoxContentWrap',
                  { active: options.tenant_id },
                ]"
              >
                <div class="apexDonutChart">
                  <ApexDonutChart
                    :data="dashboadVehicleStatus"
                    :titles="row.keys"
                    :width="'100%'"
                    :height="'270px'"
                  />
                </div>
                <div
                  v-for="(val, st) in row.keys"
                  :key="st"
                  :class="val"
                  class="largeItemContentBoxWrap"
                  @click="
                    (options.tenant_id || options.fleetId) &&
                      goToTelematics(val)
                  "
                >
                  <div :class="['largeItemsContentBoxes', getIconColor(val)]">
                    <div class="iconBox">
                      <Icons name="grid-box" />
                    </div>
                    <div class="contentBox">
                      <p
                        :class="
                          options.tenant_id || options.fleetId
                            ? 'open-link'
                            : ''
                        "
                      >
                        <span>Total</span> {{ $t(`vehicle_${val}`) }}
                      </p>
                    </div>
                    <div class="valueBox">
                      {{ dashboadVehicleStatus[val] }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </draggable>
      </div>
    </div>
    <Modal
      v-if="isActiveModal"
      title="active_trips"
      :is-visible="isActiveModal"
      is-view
      :toggle="() => (isActiveModal = false)"
    >
      <active-trips :options="options" />
    </Modal>
    <!-- custom Html End-->
  </div>
</template>
<script>
import draggable from "vuedraggable";
import { mapGetters } from "vuex";
export default {
  components: {
    draggable,
    Icons: () => import("@/components/base/icons.vue"),
    Modal: () => import("@/components/Modals/Modal.vue"),
    ApexDonutChart: () => import("@/components/sections/ApexDonutChart.vue"),
    ActiveTrips: () => import("../utils/ActiveTrips.vue"),
    ApexBarChartWithPlotOptions: () =>
      import("@/components/sections/ApexBarChartWithPlotOptions.vue"),
  },
  props: {
    loading: {
      type: Boolean,
      default() {
        return false;
      },
    },
    options: {
      type: Object,
      default() {
        return {};
      },
    },
    vehicleTech: {
      type: String,
      default() {
        return "";
      },
    },
  },
  data() {
    return {
      objObject: {},
      isActiveModal: false,
      visibleButton: false,
      cursorDrill: false,
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      isLoading: "dashboard/getIsLoading",
      dashboadData: "dashboard/getDashboadData",
      dashboadVehicleStatus: "dashboard/getVechileStatus",
      dashboadVehicleTrips: "dashboard/getVechileTrips",
      dashboardSort: "dashboard/getDashboadSortData",
      selectedCards: "dashboard/getSelectedCards",
    }),
    sectionTwo() {
      return this.dashboardSort?.section_2;
    },
    isDisable() {
      return this.selectedCards.length == 0 ? true : false;
    },
  },
  watch: {
    options: {
      handler: async function (v) {
        if (v) {
          this.visibleButton = false;
        }
      },
    },
  },
  methods: {
    getIconName(key) {
      if (key == "active_trips") {
        return "ev-distance-efficiency";
      } else if (key == "active_distance") {
        return "travel";
      } else if (key == "all_trips") {
        return "total-average-speed";
      } else if (key == "total_distance") {
        return "gap";
      }
    },

    openActiveModal() {
      this.isActiveModal = true;
    },

    getIconColor(key) {
      if (key == "active_trips" || key == "total_count") {
        return "blueBg";
      } else if (key == "active_distance" || key == "active_vehicles") {
        return "greenBg";
      } else if (key == "all_trips" || key == "in_active_vehicles") {
        return "orangeBg";
      } else if (key == "total_distance" || key == "offline_vehicles") {
        return "redBg";
      }
    },

    goToTelematics(val) {
      const params = {};
      if (this.authUser.isAdmin) {
        if (this.options.product == "oem") {
          params.product = this.options.product;
          params.tenant_id = this.options.tenant_id;
        } else if (this.options.product == "fleet") {
          params.product = this.options.product;
          params.fleetId = this.options.fleetId;
          params.oem_b2b_ids = this.options.oem_b2b_ids;
          params.tenant_id = this.options.tenant_id;
          params.is_independent_fleet = this.options.is_independent_fleet;
        }
      }
      //if (this.authUser.user_nature == "fleet")
      else {
        params.tenant_id = this.options.tenant_id;
      }
      params.md_make_id = this.options.md_make_id;
      params.md_model_id = this.options.md_model_id;
      if (val == "active_vehicles") {
        params.status = "active";
      } else if (val == "in_active_vehicles") {
        params.status = "inactive";
      } else if (val == "offline_vehicles") {
        params.status = "offline";
      }
      return this.$router.push({
        name: "vehicle_telematics",
        query: params,
      });
    },
    editItem() {
      const params = {};
      if (this.authUser.isAdmin) {
        if (this.options.product == "oem") {
          params.product = this.options.product;
          params.tenant_id = this.options.tenant_id;
        } else if (this.options.product == "fleet") {
          params.product = this.options.product;
          params.fleetId = this.options.fleetId;
          params.oem_b2b_ids = this.options.oem_b2b_ids;
          params.tenant_id = this.options.tenant_id;
        }
      } else {
        params.tenant_id = this.options.tenant_id;
      }
      params.md_make_id = this.options.md_make_id;
      params.md_model_id = this.options.md_model_id;
      params.dateType = this.options.dateType;
      params.dateRange = this.options.date;
      return this.$router.push({
        name: "trips",
        query: params,
      });
    },
    updateOrder() {
      const params = {
        section: "section_2",
        sort: true,
        item: this.dashboardSort.section_2,
        vehicle_tech: this.vehicleTech,
        tenant_id: this.options.tenant_id,
      };

      this.$store.dispatch("dashboard/updateCard", params);
    },
    resetToInitialStage() {
      let data = this.$store.getters["dashboard/getTripReset"];
      this.objObject = { ...this.options };
      this.objObject.date = [data[0].start, data[0].end];
      if (data.start == null && data.end == null) {
        delete this.objObject.dateType;
      }
      this.visibleButton = false;
      this.resetCursorValue(true);
      this.$store.dispatch("dashboard/setTripBreadCrumb", []);
      this.$store.dispatch("dashboard/vechicleTrips", this.objObject);
    },

    resetButton() {
      this.visibleButton = true;
    },
    resetCursorValue(value) {
      this.cursorDrill = value;
    },
  },
};
</script>
<style lang="sass" scoped>

.section-two-in-active
  .v-card
    cursor: auto !important

.section-two
  user-select: none
  .v-card
    cursor: all-scroll
    transition: 0.7s all ease
    background: #d5f1d5 !important
    &:hover
      box-shadow: 2px 1px 8px #20a390 !important
      transform : scale3d(1.2, 1.3, 1.9)

    label
      font-size: 12px
    hr
      background: green
    span
      font-size: 16px
      font-weight: 600
</style>
